<template>
  <div class="reservation-coupon">
    <div class="header">
      <div class="header-left">
        <div class="title">{{ $$FormData.title }}</div>
        <div class="time-box">
          <template v-if="days > 0">
            <div class="time-item" :style="{ 'backgroundColor': $$FormData.timeBgColor, 'color': $$FormData.timeColor, borderRadius: ($$FormData.round ) * 0.5 + 'px' }">{{days}}</div>
            <div class="time-box-dot" :style="{color: $$FormData.timeBgColor}">天</div>
          </template>
          <div class="time-item" :style="{ 'backgroundColor': $$FormData.timeBgColor, 'color': $$FormData.timeColor, borderRadius: ($$FormData.round ) * 0.5 + 'px' }">{{hours}}</div>
          <div class="time-box-dot" :style="{color: $$FormData.timeBgColor}">:</div>
          <div class="time-item" :style="{ 'backgroundColor': $$FormData.timeBgColor, 'color': $$FormData.timeColor, borderRadius: ($$FormData.round ) * 0.5 + 'px' }">{{minutes}}</div>
          <div class="time-box-dot" :style="{color: $$FormData.timeBgColor}">:</div>
          <div class="time-item" :style="{ 'backgroundColor': $$FormData.timeBgColor, 'color': $$FormData.timeColor, borderRadius: ($$FormData.round ) * 0.5 + 'px' }">{{seconds}}</div>
        </div>
      </div>
      <div class="more-link">查看更多</div>
    </div>
    <div class="des">{{ $$FormData.desc }}</div>
    <div class="goods-box">
      <div class="goods-item" v-for="(item, index) in $$FormData.goodsList" :key="index">
        <img class="goods-img" :style="{ borderRadius: ($$FormData.goodsRound ) * 0.5 + 'px' }" :src="defaultImg" alt="">
        <div class="price-box">
          <div v-if="$$FormData.goodsPriceShow" class="price" :style="{ color: $$FormData.goodsPriceColor }">¥88</div>
          <div v-if="$$FormData.goodsOriginalPriceShow" class="original-price" :style="{ color: $$FormData.goodsOriginalPriceColor }">¥100</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReservationPreview',
  data() {
    return {
      defaultImg: `https://testscs.doufenle.com/store/c-mini/goods-no-data.png`,
      days: 0,
      hours: '00',
      minutes: '00',
      seconds: '00',
      timer: null,
    }
  },
  methods: {
    // 计算倒计时
    calculateTime() {
      if (!this.$$FormData.endTime) {
        return
      }
      
      const end = new Date(this.$$FormData.endTime).getTime()
      const now = new Date().getTime()
      let diff = end - now

      if (diff <= 0) {
        this.days = 0
        this.hours = '00'
        this.minutes = '00'
        this.seconds = '00'
        if (this.timer) {
          clearInterval(this.timer)
        }
        return
      }

      // 计算天数、小时、分钟、秒
      this.days = Math.floor(diff / (1000 * 60 * 60 * 24))
      diff -= this.days * (1000 * 60 * 60 * 24)
      
      const hoursNum = Math.floor(diff / (1000 * 60 * 60))
      this.hours = hoursNum < 10 ? `0${hoursNum}` : `${hoursNum}`
      diff -= hoursNum * (1000 * 60 * 60)
      
      const minutesNum = Math.floor(diff / (1000 * 60))
      this.minutes = minutesNum < 10 ? `0${minutesNum}` : `${minutesNum}`
      diff -= minutesNum * (1000 * 60)
      
      const secondsNum = Math.floor(diff / 1000)
      this.seconds = secondsNum < 10 ? `0${secondsNum}` : `${secondsNum}`
    },
    startTimer() {
      this.calculateTime()
      this.timer = setInterval(() => {
        this.calculateTime()
      }, 1000)
    }
  },
  mounted() {
    this.startTimer()
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  watch: {
    '$$FormData.endTime': {
      handler(newVal) {
        if (this.timer) {
          clearInterval(this.timer)
        }
        if (newVal) {
          this.startTimer()
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.reservation-coupon {
  background-color: #fff;
  border-radius: 6px;
  padding: 14px 10px;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      margin-right: 6px;
      font-size: 14px;
      font-weight: 600;
    }
    .header-left {
      display: flex;
      align-items: center;
    }

    .time-box {
      display: flex;
      align-items: center;
      .time-box-dot {
        font-size: 12px;
        font-weight: bold;
        margin: 0 4px;
      }

      .time-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        font-size: 12px;
      }
    }
    .more-link {
      font-size: 14px;
      color: #444444;
    }
  }
  .des {
    font-size: 12px;
    color: #888888;
    margin: 6px 0;
  }
  .goods-box {
    overflow-x: auto;
    display: flex;
    flex-wrap: nowrap;
    .goods-item {
      margin-right: 10px;
      .goods-img {
        width: 75px;
        height: 75px;
      }
      .price-box {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 4px;
        .price {
          font-size: 12px;
          font-weight: bold;
          margin-right: 4px;
        }
        .original-price {
          font-size: 11px;
          text-decoration: line-through;
        }
      }

    }
  }
}
</style>