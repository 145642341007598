<template>
  <div>
    <el-dialog
      title="选择商品"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      append-to-body
      width="1200px"
    >
      <div class="container">
        <div class="tabs">
          <el-radio-group v-model="activeName" @input="onTabClick">
            <el-radio-button label="project">项目</el-radio-button>
            <el-radio-button label="product">产品</el-radio-button>
            <el-radio-button label="card">次卡</el-radio-button>
          </el-radio-group>
          <el-input
            :placeholder="placeholderText"
            v-model="name"
            suffix-icon="el-icon-search"
            @change="getList()"
            clearable
          ></el-input>
        </div>

        <!-- 项目 -->
        <div v-show="activeName === 'project'" class="content">
          <div class="category">
            <div class="list-item c9">分类</div>
            <el-tree
              :data="projectCategory"
              :props="defaultPropsProject"
              ref="treeRef"
              node-key="spc_id"
              empty-text="暂无项目分类信息"
              default-expand-all
              @node-click="handlePjtClick"
              :expand-on-click-node="false"
            ></el-tree>
          </div>
          <div class="table">
            <el-table
              ref="projectData"
              :data="projectData"
              row-key="shop_project_id"
              @row-click="handleRowProjectClick"
            >
              <template slot="empty">
                <el-empty description="暂无数据"></el-empty>
              </template>
              <el-table-column width="240px" label="项目名称">
                <template slot-scope="scope">
                  <el-radio
                    v-model="project_id"
                    :label="scope.row.project_id"
                    >{{ scope.row.project_name }}</el-radio
                  ></template
                ></el-table-column
              >

              <el-table-column
                prop="spc_name"
                label="项目分类"
              ></el-table-column>
              <el-table-column label="规格">
                <template slot-scope="scope">{{
                  scope.row.specs || "-"
                }}</template>
              </el-table-column>
              <el-table-column label="价格">
                <template slot-scope="scope"
                  >￥{{
                    (Number(scope.row.original_price) / 100).toFixed(2)
                  }}</template
                >
              </el-table-column>
              <el-table-column label="来源">
                <template slot-scope="scope">{{
                  scope.row.shop_name || "-"
                }}</template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!-- 产品 -->
        <div v-show="activeName === 'product'" class="content">
          <div class="category">
            <div class="list-item c9">分类</div>
            <el-tree
              :data="productCategory"
              :props="defaultProps"
              ref="treeRef"
              node-key="category_id"
              empty-text="暂无产品分类信息"
              default-expand-all
              @node-click="handlePdtClick"
              :expand-on-click-node="false"
            ></el-tree>
          </div>
          <div class="table">
            <el-table
              ref="productData"
              :data="productData"
              row-key="product_id"
              :tree-props="{ children: 'sku_list' }"
              default-expand-all
              @row-click="handleRowProductClick"
            >
              <template slot="empty">
                <el-empty description="暂无数据"></el-empty>
              </template>
              <el-table-column label="产品名称" width="240px">
                <template slot-scope="scope">
                  <el-radio
                    v-model="product_id"
                    :label="scope.row.product_id"
                    >{{ scope.row.name }}</el-radio
                  ></template
                ></el-table-column
              >
              <!-- <el-table-column prop="name" label="产品名称"></el-table-column> -->
              <el-table-column label="分类">
                <template slot-scope="scope">{{
                  scope.row.category_names || "-"
                }}</template>
              </el-table-column>
              <el-table-column label="单位">
                <template slot-scope="scope">{{
                  scope.row.unit || "-"
                }}</template>
              </el-table-column>
              <el-table-column prop="stock" label="可用库存">
                <template slot-scope="scope">{{
                  scope.row.stock || 0
                }}</template>
              </el-table-column>
              <el-table-column label="价格">
                <template slot-scope="scope">
                  ￥{{ (Number(scope.row.retail_price) / 100).toFixed(2) }}
                </template>
              </el-table-column>
              <el-table-column label="来源">
                <template slot-scope="scope">{{
                  scope.row.shop_name || "-"
                }}</template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!-- 次卡 -->
        <div v-show="activeName === 'card'" class="content">
          <el-table
            ref="cardTableRef"
            :data="cardData"
            row-key="shop_card_id"
            @row-click="handleRowCardClick"
          >
            <template slot="empty">
              <el-empty description="暂无数据"></el-empty>
            </template>
            <el-table-column width="240px" label="卡号">
              <template slot-scope="scope">
                <el-radio v-model="shop_card_id" :label="scope.row.shop_card_id">{{ scope.row.shop_card_num }}</el-radio>
                </template>
              </el-table-column>
            <el-table-column prop="shop_card_name" label="次卡名称"></el-table-column>
            <el-table-column label="售价">
              <template slot-scope="scope">￥{{(Number(scope.row.sell_price) / 100).toFixed(2)}}</template>
            </el-table-column>
            <el-table-column label="次数">
              <template slot-scope="scope">{{scope.row.project_num}}</template>
            </el-table-column>
            <el-table-column label="有效期">
              <template slot-scope="scope">{{scope.row.invalid_time ? scope.row.invalid_time : "永久有效"}}</template>
            </el-table-column>
          </el-table>
        </div>
        <div v-show="activeName === 'project'" class="pagination">
          <div>
            共{{ Math.ceil(page_project.total / page_project.page_size) }}页，{{
              page_project.total
            }}条数据
          </div>
          <el-pagination
            background
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
            :current-page="page_project.page_no"
            :page-size="page_project.page_size"
            :total="page_project.total"
          ></el-pagination>
        </div>
        <div v-show="activeName === 'product'" class="pagination">
          <div>
            共{{ Math.ceil(page_product.total / page_product.page_size) }}页，{{
              page_product.total
            }}条数据
          </div>
          <el-pagination
            background
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
            :current-page="page_product.page_no"
            :page-size="page_product.page_size"
            :total="page_product.total"
          ></el-pagination>
        </div>
        <div v-show="activeName === 'card'" class="pagination">
          <div>
            共{{ Math.ceil(page_card.total / page_card.page_size) }}页，{{
              page_card.total
            }}条数据
          </div>
          <el-pagination
            background
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
            :current-page="page_card.page_no"
            :page-size="page_card.page_size"
            :total="page_card.total"
          ></el-pagination>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getProductList,
  findPageByMall,
  getProductTree,
  getCardList,
} from "@/api/shop";
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultProps: {
        children: "children",
        label: "name",
      },
      defaultPropsProject: {
        children: "children",
        label: "name",
      },
      loading: false,
      name: "",
      activeName: "project",
      categoryVal: "",
      projectCategory: [],
      productCategory: [],
      projectData: [],
      productData: [],
      cardData: [],
      project_id: "",
      product_id: "",
      shop_card_id: "",
      page_project: {
        page_no: 1,
        page_size: 10,
        total: 0,
      },
      page_product: {
        page_no: 1,
        page_size: 10,
        total: 0,
      },
      page_card: {
        page_no: 1,
        page_size: 10,
        total: 0,
      },
    };
  },
  computed: {
    placeholderText() {
      return this.activeName === "project"
        ? "请输入项目名称"
        : "请输入产品名称";
    },
  },
  mounted() {
    this.getProCategory();
    this.getProjectList();
  },

  methods: {
    handleRowProductClick(row) {
      this.product_id = row.product_id;
    },
    handleRowProjectClick(row) {
      this.project_id = row.project_id;
    },
    handleRowCardClick(row) {
      this.shop_card_id = row.shop_card_id;
    },
    // 点击产品分类
    handlePdtClick(data) {
      this.handleCate(data.mc_id, "product");
    },
    // 点击项目分类
    handlePjtClick(data) {
      this.handleCate(data.mc_id, "project");
    },
    // 点击左侧分类
    handleCate(id, type) {
      this.categoryVal = id;
      if (type === "project") {
        this.page_project.page_no = 1;
        this.getProjectList();
      } else if(type==="product") {
        this.page_product.page_no = 1;
        this.getProductList();
      } else if(type==="card") {
        this.page_card.page_no = 1;
        this.getCardList();
      }
    },
    // 项目/产品切换
    onTabClick() {
      this.onReset();
      if (this.activeName === "project") {
        this.getProCategory();
        this.getProjectList();
      } else if(this.activeName==="product") {
        this.getPdtCategory();
        this.getProductList();
      } else if(this.activeName==="card") {
        this.getCardList();
      }
    },
    // 分页查询
    handleCurrentChange(page) {
      this.getList(page);
    },
    // 选择分类
    getList(page) {
      if (this.activeName === "project") {
        this.page_project.page_no = page ? page : 1;
        this.getProjectList();
      } else if(this.activeName==="product") {
        this.page_product.page_no = page ? page : 1;
        this.getProductList();
      } else if(this.activeName==="card") {
        this.page_card.page_no = page ? page : 1;
        this.getCardList();
      }
    },
    //  重置
    onReset() {
      this.name = "";
      this.categoryVal = "";
      this.page_project.page_no = 1;
      this.page_product.page_no = 1;
    },
    // 项目分类
    async getProCategory() {
      try {
        const { data } = await await getProductTree({ mc_type: 1 });
        this.projectCategory = data;
      } catch (e) {
        console.log(e);
      }
    },

    // 产品分类
    async getPdtCategory() {
      try {
        const { data } = await getProductTree({ mc_type: 0 });
        this.productCategory = data;
      } catch (e) {
        console.log(e);
      }
    },

    // 项目列表
    async getProjectList() {
      try {
        this.loading = true;
        const { page_no, page_size } = this.page_project;
        const params = {
          mall_spc_id: this.categoryVal || undefined,
          project_name: this.name,
          page_no,
          page_size,
        };
        const res = await findPageByMall(params);
        if (!res.code) {
          this.projectData = res.data;
          this.page_project.total = res.data_total;
        }
        this.project_id = "";
      } finally {
        this.loading = false;
      }
    },
    // 产品列表
    async getProductList() {
      try {
        this.loading = true;
        const { page_no, page_size } = this.page_product;
        const params = {
          mc_id: this.categoryVal || undefined,
          product_name: this.name,
          push_store: 0,
          page_no,
          page_size,
        };
        const res = await getProductList(params);
        if (!res.code) {
          this.productData = res.data;
          this.page_product.total = res.data_total;
        }
        this.product_id = "";
      } finally {
        this.loading = false;
      }
    },
    // 次卡列表
    async getCardList() {
      const res=await getCardList({
        card_name:this.name,
        page_no:this.page_card.page_no,
        page_size:this.page_card.page_size
      })
      if(!res.code){
        this.cardData=res.data
        this.page_card.total=res.data_total
      }
      this.shop_card_id = "";
    },
    handleSubmit() {
      if (this.product_id == "" && this.project_id == "" && this.card_id == "") {
        return this.$message.info("请选择一个产品或项目");
      }
      let params = {};
      if (this.activeName === "project") {
        const obj = this.findCurrentItem(this.project_id);
        params.type = "project";
        params.label = obj.project_name;
        params.project_id = this.project_id;
        params.value = `/pages/goodsDetails/projectDetails?product_id=${this.project_id}`;
      } else if(this.activeName==="product") {
        const obj = this.findCurrentItem(this.product_id);
        params.type = "product";
        params.label = obj.name;
        params.product_id = this.product_id;
        params.value = `/pages/goodsDetails/goodsDetails?product_id=${this.product_id}`;
      } else if(this.activeName==="card") {
        const obj = this.findCurrentItem(this.shop_card_id);
        params.type = "card";
        params.label = obj.shop_card_name;
        params.product_id = this.shop_card_id;
        params.value = `/pages/goodsDetails/cardDetails?product_id=${this.shop_card_id}`;
      }
      this.emitClose(true, params);

      this.handleClose();
    },

    findCurrentItem(id) {
      let obj = {};
      if (this.activeName === "project") {
        const index = this.projectData.findIndex(
          (item) => item.project_id === id
        );
        obj = this.projectData[index];
      } else if(this.activeName==="product") {
        const index = this.productData.findIndex(
          (item) => item.product_id === id
        );
        obj = this.productData[index];
      } else if(this.activeName==="card") {
        const index = this.cardData.findIndex(
          (item) => item.shop_card_id === id
        );
        obj = this.cardData[index];
      }
      return obj;
    },
    emitClose(isRefsh, params) {
      this.$emit("submit", { params, isRefsh });
    },

    handleClose() {
      this.product_id = "";
      this.project_id = "";
      this.shop_card_id = "";
      //这里必须用update:来触发
      this.emitClose(false, {});
      this.$emit("update:dialogVisible", false);
    },
  },
};
</script>

<style lang="scss" scoped>
// /deep/ .el-dialog__header {
//   padding: 24px 0 24px 24px;
//   border-bottom: 1px solid rgba(0, 0, 0, 0.1);
// }
// /deep/ .el-dialog__body {
//   padding: 24px 20px 20px 20px;
// }
/deep/ .el-dialog {
  margin-top: 5vh !important;
}
.container {
  padding: 0 20px;
}

.tabs {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 24px;

  /deep/ .el-input {
    width: 300px;
  }
  /deep/ .is-active .el-radio-button__inner {
    background: #409eff !important;
    border-color: #409eff !important;
  }
}

.content {
  display: flex;

  .category {
    width: 200px;
    height: 500px;
    border: 1px solid #dee2eb;
    padding: 5px 12px;
    margin-right: 15px;
    overflow: auto;

    .list-item {
      font-size: 14px;
      height: 40px;
      line-height: 40px;
      padding: 0 15px;
      cursor: pointer;
    }
  }

  .table {
    flex: 1;
    height: 500px;
    overflow: auto;
  }
}

.btn-item {
  margin-top: 24px;
  text-align: right;

  .btn {
    width: 108px;
  }
}

.pagination {
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin-top: 28px;
}

/deep/ .el-radio__label {
  white-space: normal !important;
  padding-left: 10px !important;

  display: block !important;
  line-height: 18px !important;
}
/deep/ .el-radio {
  display: flex;
  align-items: center;
}
</style>
