//图片压缩
export function zipImg(file) {
  return new Promise((resolve) => {
      if (file && file.size / 1024 / 1024 > 5) {
          let img = new Image()
          img.src = URL.createObjectURL(file)
          let cvs = document.createElement('canvas')
          let maxRatio = 0.75 // 大图比率
          let minRatio = 0.8 // 小图比率
          let imgQulity = 0.5 // 图像质量
          img.onload = async function () {
              let ratio = (img.naturalWidth > 1000 || img.naturalHeight > 1000) ? maxRatio : minRatio
              cvs.width = img.naturalWidth * ratio
              cvs.height = img.naturalHeight * ratio
              let ctx = cvs.getContext('2d')
              ctx.drawImage(img, 0, 0, cvs.width, cvs.height)
              // 压缩后新图的 base64
              let zipBase64 = cvs.toDataURL(file.type, imgQulity)
              let fileFixed = await dataURLtoFile(zipBase64, file.name, file.type)
              resolve(fileFixed)
          }
      } else { resolve(file) }
  })
}



// base64转图片
export function dataURLtoFile(dataurl, filename, mime) {
  return new Promise((resolve) => {
      let arr = dataurl.split(';base64,')
      let suffix = mime.split('/')[1]
      let bstr = atob(arr[1])
      let n = bstr.length
      let u8arr = new Uint8Array(n)
      while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
      }
      let data = new File([u8arr], `${filename}.${suffix}`, {
          type: mime
      })
      resolve(data)
  })
}