<template>
  <div class="goods-setting-selector">
    <div class="goods-title">添加商品</div>
    <el-button class="enable_btn" @click="handleOpenGoodsPicker">
      <span>选择商品{{$$FormData.goodsList.length}}/50</span>
    </el-button>
    <el-dialog v-if="goods_picker_show" :visible.sync="goods_picker_show" top="5vh" :close-on-click-modal="false" :close-on-press-escape="false" title="产品选择" append-to-body width="950px">
     <div style="margin-bottom: 16px; display: flex; align-items: center;justify-content: space-between;">
      <el-radio-group v-model="activeName" @input="onTabClick">
        <el-radio-button label="product">产品</el-radio-button>
        <el-radio-button label="project">项目</el-radio-button>
      </el-radio-group>
      <el-input style="width: 200px;margin-right: 16px;" v-model="keyword" placeholder="请输入名称" @keyup.enter.native="onSearch" clearable></el-input>
     </div>
      <div class="container">
        <div class="category">
          <div class="list-item">分类</div>
          <el-tree
            v-if="activeName === 'product'"
            :data="categoryConsumables"
            node-key="category_id"
            default-expand-all
            :props="{ children: 'children', label: 'name' }"
            @node-click="handleCateConsumables"
          ></el-tree>
          <div v-else>
            <el-tree :data="categoryConsumables" node-key="spc_id" default-expand-all :props="{ children: 'children', label: 'spc_name' }" @node-click="handleCateConsumables"></el-tree>
          </div>
        </div>
        <div class="table-container">
          <el-table ref="tableDataRef" :data="tableData" :header-cell-style="{ background: '#F8F9FC', color: '#333333' }" height="100%" @selection-change="handeleSelect" @select="handeleSelect">
            <template slot="empty"><el-empty :description="'暂无数据'"></el-empty></template>
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column :label="'名称'" prop="name" width="150">
              <template slot-scope="scope">{{ scope.row.name || scope.row.project_name }}</template>
            </el-table-column>
            <el-table-column :label="'价格'" prop="unit">
              <template slot-scope="scope">
                <span v-if="activeName === 'product' && scope.row && scope.row.sku_spec_list">{{ scope.row.sku_spec_list[0].retail_price / 100 }}</span>
                <span v-else-if="activeName === 'project' && scope.row">{{ scope.row.sell_price / 100 }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="'供应商'" prop="shop_name"></el-table-column>
          </el-table>
          <div class="pagination">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pageParams.page_no"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="pageParams.page_size"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            ></el-pagination>
          </div>
        </div>
      </div>
      <div slot="footer" class="goods-picker-footer">
        <span>
          <el-button @click="goods_picker_show = false">取 消</el-button>
          <el-button type="primary" @click="handleConfirm">确 定</el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getProductTree, getProductList, getProjectList, getProjectCategoryList } from '@/api/goods'
import Storage from '@/utils/storage'
import { decorType } from '@/utils/decor-types'
export default {
  name: 'GoodsSettingSelector',
  data() {
    return {
      categoryConsumables: [],
      tableData: [],
      keyword:'',
      pageParams: {
        category_id: '',
        spc_id: '',
        page_no: 1,
        page_size: 10,
        shop_id: ''
      },
      total: 0,
      goods_picker_show: false,
      activeName: 'product',
      shop_id: '',
      productSelect: [],
      projectSelect: []
    }
  },
  async mounted() {},
  methods: {
    // 获取产品分类
    async getPdtCategory() {
      const shop_user = Storage.getItem(`${decorType}_user`)
      this.shop_id = JSON.parse(shop_user || '{}').shop_id
      this.pageParams.shop_id = this.shop_id
      if (this.activeName === 'product') {
        const { data } = await getProductTree({ shop_id: this.shop_id })
        this.categoryConsumables = data
      } else {
        const { data } = await getProjectCategoryList({ shop_id: this.shop_id, flag: true })
        this.categoryConsumables = data
      }
    },
    // 获取列表
    getTableList() {
      if (this.activeName === 'project') {
        this.getProjectListFn()
      } else {
        this.getProductListFn()
      }
    },

    //反钩选表格
    async handleTableSelect() {
      await this.$nextTick()
      this.$refs.tableDataRef.clearSelection()
      const ids = this.activeName === 'product' ? this.productSelect : this.projectSelect
      const idKey = this.activeName === 'product' ? 'product_id' : 'project_id'
      console.log(this.activeName, ids)
      ids.forEach(id => {
        const row = this.tableData.find(item => item[idKey] === id.obj_id)
        console.log(row, 'row')
        if (row) {
          this.$refs.tableDataRef.toggleRowSelection(row, true)
        }
      })
    },

    //获取产品列表
    async getProductListFn() {
      const res = await getProductList({...this.pageParams, product_name: this.keyword})
      this.tableData = res.data
      this.total = res.data_total
      await this.handleTableSelect()
    },
    //获取项目列表
    async getProjectListFn() {
      const res = await getProjectList({...this.pageParams,project_name: this.keyword})
      this.tableData = res.data
      this.total = res.data_total
      await this.handleTableSelect()
    },

    // 分类点击
    handleCateConsumables(node) {
      this.pageParams.category_id = node.category_id
      this.pageParams.spc_id = node.spc_id
      this.pageParams.page_no = 1
      this.getTableList()
    },
    // 选择
    handeleSelect(list) {
      if (this.activeName === 'product') {
        // 获取当前页面上所有的product_id
        const currentPageIds = this.tableData.map(item => item.product_id);
        
        // 保留不在当前页面的已选项
        const keepSelected = this.productSelect.filter(item => 
          !currentPageIds.includes(item.obj_id)
        );
        
        // 添加当前页面新选中的项
        const newSelected = list.map(item => ({
          obj_id: item.product_id,
          obj_type: '3'
        }));
        
        // 更新productSelect，合并保留的选项和新选中的选项
        this.productSelect = [...keepSelected, ...newSelected];
        
        // 如果有取消选中的项，也要从projectSelect中移除
        const selectedIds = new Set(this.productSelect.map(item => item.obj_id));
        this.projectSelect = this.projectSelect.filter(item => 
          !currentPageIds.includes(item.obj_id) || selectedIds.has(item.obj_id)
        );
      } else {
        // 获取当前页面上所有的project_id
        const currentPageIds = this.tableData.map(item => item.project_id);
        
        // 保留不在当前页面的已选项
        const keepSelected = this.projectSelect.filter(item => 
          !currentPageIds.includes(item.obj_id)
        );
        
        // 添加当前页面新选中的项
        const newSelected = list.map(item => ({
          obj_id: item.project_id,
          obj_type: '1'
        }));
        
        // 更新projectSelect，合并保留的选项和新选中的选项
        this.projectSelect = [...keepSelected, ...newSelected];
        
        // 如果有取消选中的项，也要从productSelect中移除
        const selectedIds = new Set(this.projectSelect.map(item => item.obj_id));
        this.productSelect = this.productSelect.filter(item => 
          !currentPageIds.includes(item.obj_id) || selectedIds.has(item.obj_id)
        );
      }
    },
    // 分页
    handleSizeChange(size) {
      this.pageParams.page_no = 1
      this.pageParams.page_size = size
      this.getTableList()
    },
    handleCurrentChange(page) {
      this.pageParams.page_no = page
      this.getTableList()
    },
    // 切换
    onTabClick() {
      this.getPdtCategory()
      this.getTableList()
    },
    async handleOpenGoodsPicker() {
      this.goods_picker_show = true;
      
      // 根据obj_type分类已选商品
      const goodsList = this.$$FormData.goodsList || [];
      this.productSelect = goodsList.filter(item => item.obj_type === '3');
      this.projectSelect = goodsList.filter(item => item.obj_type === '1');
      
      await this.getPdtCategory();
      this.getTableList();
    },
    onSearch(){
      this.getTableList();
    },
    // 确定
    handleConfirm() {
      // 合并产品和项目列表
      let list = [...this.productSelect, ...this.projectSelect];
      
      // 使用Map根据obj_id去重
      const uniqueMap = new Map();
      list.forEach(item => {
        if (!uniqueMap.has(item.obj_id)) {
          uniqueMap.set(item.obj_id, item);
        }
      });
      
      // 转换回数组
      list = Array.from(uniqueMap.values());
      
      // 更新表单数据
      this.$$FormData.goodsList = list;
      this.goods_picker_show = false;
      console.log(this.$$FormData.goodsList);
    }
  }
}
</script>

<style lang="scss" scoped>
.goods-setting-selector {
  width: 100%;
  padding: 12px 16px;
  box-sizing: border-box;
  background-color: #ffffff;
  .goods-title {
    width: 100%;
    height: 30px;
    font-size: 14px;
    color: #969799;
    line-height: 18px;
    white-space: nowrap;
  }
  .enable_btn {
    width: 100%;
    height: 32px;
    background: #ecf5ff;
    border: 1px dashed #b3d8ff;
    text-align: center;
    color: #333333;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
  }
}
.container {
  display: flex;
  margin-top: 13px;
  .category {
    width: 200px;
    height: 550px;
    border: 1px solid #dee2eb;
    padding: 5px 12px;
    margin-right: 15px;
    overflow: auto;
    .list-item {
      font-size: 14px;
      height: 40px;
      line-height: 40px;
      padding: 0 15px;
      cursor: pointer;
    }
  }
  .table-container {
    height: 550px;
    width: calc(100% - 200px);
  }
  .pagination {
    text-align: right;
    margin: 16px 0;
  }
}
</style>
