import { render, staticRenderFns } from "./SeckilPreview.vue?vue&type=template&id=1552b181&scoped=true"
import script from "./SeckilPreview.vue?vue&type=script&lang=js"
export * from "./SeckilPreview.vue?vue&type=script&lang=js"
import style0 from "./SeckilPreview.vue?vue&type=style&index=0&id=1552b181&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1552b181",
  null
  
)

export default component.exports