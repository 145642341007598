import SettingTypes from "@/utils/setting-types";
export default {
    name: 'reservation',
    label: {
      title: '秒杀模块',
      icon: require('@/assets/icon-component-label_Seckill.svg'),
      limit: 10
    },
    sort: 999,
    data:{
      title:'限时秒杀',//标题
      desc:'好物专享 等你来抢',//描述
      goodsList: [],//秒杀商品
      moreLink:'',//更多链接
      endTime:'',//活动结束时间
      round:4,//倒计时圆角
      timeColor:'#FFFFFF',//倒计时文字颜色
      timeBgColor:'#000000',//倒计时背景颜色
      goodsRound:6,//商品图片圆角
      pageMargin:20,//页面上下边距
      goodsPriceShow:1,//商品价格是否显示
      goodsOriginalPriceShow:1,//商品原价是否显示
      goodsPriceColor:'#FF6543',//商品价格颜色
      goodsOriginalPriceColor:'#999999',//商品原价颜色
    },
    settings:{
      custom:{
        type: SettingTypes.custom,
        component_name: 'SeckillGoodsSelect'
      }
    }
  }
  