<template>
  <div class="seckill-goods-select">
    <div class="title">内容设置</div>
    <div class="box-content">
      <div class="item">
        <span class="item-label">标题</span>
        <el-input v-model="$$FormData.title" placeholder="请输入标题" />
      </div>
      <div class="item">
        <span class="item-label">描述</span>
        <el-input v-model="$$FormData.desc" placeholder="请输入描述" />
      </div>
      <div class="item">
        <span class="item-label">选择商品</span>
        <el-button class="enable_btn" @click="handleAddGoods">
          <span>选择秒杀商品{{$$FormData.goodsList.length}}/50</span>
        </el-button>
      </div>
      <div class="item">
        <span class="item-label">查看更多</span>
        <link-selector v-model="$$FormData.moreLink" />
      </div>
    </div>
    <div class="title m-12">倒计时</div>
    <div class="box-content">
      <div class="item">
        <span class="item-label">活动结束时间</span>
        <el-date-picker v-model="$$FormData.endTime" type="datetime" placeholder="选择日期"></el-date-picker>
      </div>
      <div class="item">
        <span class="item-label">倒计时圆角</span>
        <div class="block" style="flex: 1;">
          <el-slider v-model="$$FormData.round" show-input></el-slider>
        </div>

      </div>
      <div class="item">
        <span class="item-label">倒计时文字颜色</span>
        <div class="item-right">
          <el-color-picker v-model="$$FormData.timeColor"></el-color-picker>
        </div>
      </div>
      <div class="item">
        <span class="item-label">倒计时背景颜色</span>
        <div class="item-right">
          <el-color-picker v-model="$$FormData.timeBgColor"></el-color-picker>
        </div>
      </div>
    </div>
    <div class="title m-12">样式设置</div>
    <div class="box-content">
      <div class="item">
        <span class="item-label">商品图片圆角</span>
        <div class="block" style="flex: 1;">
          <el-slider v-model="$$FormData.goodsRound" show-input></el-slider>
        </div>
      </div>
      <div class="item">
        <span class="item-label">页面上下边距</span>
        <div class="block" style="flex: 1;">
          <el-slider v-model="$$FormData.pageMargin" show-input></el-slider>
        </div>
      </div>
      <div class="item">
        <span class="item-label">商品价格</span>
        <div class="item-right">
          <el-radio-group v-model="$$FormData.goodsPriceShow">
            <el-radio-button :label="1">显示</el-radio-button>
            <el-radio-button :label="0">隐藏</el-radio-button>
          </el-radio-group>
        </div>
      </div>
      <div class="item">
        <span class="item-label">商品原价</span>
        <div class="item-right">
          <el-radio-group v-model="$$FormData.goodsOriginalPriceShow">
            <el-radio-button :label="1">显示</el-radio-button>
            <el-radio-button :label="0">隐藏</el-radio-button>
          </el-radio-group>
        </div>
      </div>
      <div class="item">
        <span class="item-label">商品价格颜色</span>
        <div class="item-right">
          <el-color-picker v-model="$$FormData.goodsPriceColor"></el-color-picker>
        </div>
      </div>
      <div class="item">
        <span class="item-label">商品原价颜色</span>
        <div class="item-right">
          <el-color-picker v-model="$$FormData.goodsOriginalPriceColor"></el-color-picker>
        </div>
      </div>
    </div>
    <seckill-Goods-dialog v-if="seckillGoodsDialogVisible" :dialogVisible.sync="seckillGoodsDialogVisible" />

  </div>
</template>

<script>
import SeckillGoodsDialog from '@/components/customComponents/seckillGoodsDialog/index.vue'
export default {
  name: 'SeckillGoodsSelect',
  components: { SeckillGoodsDialog },
  data() {
    return {
      goods_picker_show: false,
      seckillGoodsDialogVisible: false
    }
  },
  computed: {
    defaultGoodsIds() {
      return this.$$FormData.goodsList.map(item => item.goods_id)
    }
  },
  methods: {
    handleAddGoods() {
      this.seckillGoodsDialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.seckill-goods-select {
  width: 100%;
  padding: 12px 16px;
  box-sizing: border-box;
  background-color: #ffffff;

  .m-12 {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .box-content {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    padding: 12px 16px;
  }

  .item {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    .item-label {
      width: 100px;
    }

    .item-right {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .enable_btn {
    width: 240px;
    height: 32px;
    background: #ecf5ff;
    border: 1px dashed #b3d8ff;
    text-align: center;
    color: #333333;
    margin-left: 80px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
  }

}
</style>
