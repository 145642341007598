<template>
  <div class="link-selector">
    <a
      v-if="fullPath"
      href="javascript:void(0)"
      :title="fullPath.pathText"
      class="link-selector__link"
    >
      <el-tag :closable="!disabled" @close="clearLink">
        <div class="link-selector__tag-content">{{ fullPath.pathText }}</div>
      </el-tag>
    </a>
    <el-cascader
      v-else
      v-model="link_types"
      :options="options"
      :props="props"
      :disabled="disabled"
      placeholder="请选择跳转链接"
      size="mini"
      @change="handleCascaderChange"
    />
    <en-shop-picker
      :show.sync="show_shop_picker"
      :buyer-domain="$$Domain['buyer_pc']"
      :request="$$Request"
      :limit="1"
      @confirm="handleShopPickerConfirm"
    />
    <en-goods-picker
      :show.sync="show_goods_picker"
      :type="$$DecorType"
      :limit="1"
      @confirm="handleGoodsPickerConfirm"
      @close="show_goods_picker = false"
    />
    <en-page-picker
      :show.sync="show_page_picker"
      :request="$$Request"
      :domain="$$Domain"
      :limit="1"
      type="decor"
      @confirm="handlePagePickerConfirm"
    />

    <shop-dia
      v-if="showDialog"
      :dialogVisible.sync="showDialog"
      @submit="handleMainSubmit"
    />
    <activity-dialog v-if="showActivityDialog" :dialogVisible.sync="showActivityDialog" @submit="handleMainSubmit"></activity-dialog>
    <decor-dialog v-if="showDecorDialog" :dialogVisible.sync="showDecorDialog" @submit="handleMainSubmit"></decor-dialog>
  </div>
</template>

<script>
import * as Rules from "@/utils/rules";
// import * as API_Shop from "@/api/shop";
// import * as API_Pages from "@/api/pages";
import { clientType } from "@/utils/decor-types";
import ShopDia from "./dialog.vue";
import ActivityDialog from "./activityDialog.vue";
import DecorDialog from "./decorDialog.vue";
import { mapGetters } from "vuex";
export default {
  name: "LinkSelector",
  components: { ShopDia,ActivityDialog,DecorDialog },
  props: {
    value: {
      type: [Object, String],
      required: true,
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showDialog: false,
      showActivityDialog: false,
      showDecorDialog:false,
      link: "", // 旧
      link_types: "",
      options: [
        // {
        //   label: '微页面',
        //   value: '微页面',
        //   children: [
        //     { label: '微页面', value: 'mini-page' },
        //     { label: '店铺主页', value: 'shop-home' },
        //     { label: '个人中心', value: 'user-center' }
        //   ]
        // },
        {
          label: "店铺页面",
          value: "storePage",
          disabled: true,
          children: [
            { label: "个人中心", value: "/pages/tabs/mine/mine" },
            { label: "积分商城", value: "/pages/mine/integral/index" },
            { label: "购物车", value: "/pages/tabs/cart/cart" },
            { label: "预约", value: "/pages/reservation/reservation" },
            { label: "搜索页", value: "/pages/search/search" },
            { label: "会员中心", value: "/pages/mine/memberCenter/index" },
          ],
        },
        {
          label: "商品",
          value: "商品",
          disabled: true,
          children: [
            { label: "单个商品", value: "goodsOne" },
            { label: "商品分类", value: "goodsType", disabled: true },
            { label: "项目分类", value: "projectType", disabled: true },
          ],
        },
        {
          label: "营销活动",
          value: "营销活动",
          disabled: true,
          children: [
            { label: "活动", value: "activity" },
            { label: "微页面", value: "decorPage" },
          ],
        },
        {
          label: "其他",
          value: "其他",
          disabled: true,
          children: [
            // { label: "自定义链接", value: "custom" },
            {
              label: "小程序路径",
              value: "mini-program",
              disabled: clientType === "pc",
            },
            {
              label: "H5链接",
              value: "h5-link",
              disabled: clientType === "pc",
            },
          ],
        },
      ],
      props: { expandTrigger: "click", checkStrictly: true },
      shopInfo: "",
      show_shop_picker: false,
      show_goods_picker: false,
      show_page_picker: false,
      miniPageItem: "",
      shopItem: "",
    };
  },
  watch: {
    value: {
      immediate: true,
      handler: "defaultValueChange",
    },
    link_types: {
      handler: "defaultTypeChange",
    },
  },
  model: { event: "update", prop: "value" },
  mounted() {
    this.shopItem = this.$$ShopInfo;
    this.options = this.options.map((item) => {
      if (item.value == "商品") {
        item.children.map((cur) => {
          if (cur.value == "goodsType") {
            cur.children = this.goodsTypeList;
          }
          if (cur.value == "projectType") {
            cur.children = this.goodsProductTypeList;
          }
          return cur;
        });
      }
      return item;
    });
  },
  computed: {
    ...mapGetters(["goodsTypeList", "goodsProductTypeList"]),
    // fullPath() {
    //   console.log(111);
    //   return "";
    // },
    fullPath() {
      if (!this.link || !this.link_types) return;
      let path = [];
      let pathText = "";
      if (this.link.type === "product" || this.link.type === "project" || this.link.type === "activity" || this.link.type === "card") {
        pathText = this.link.label;
      } else {
        pathText = this.getText(this.link.link_types);
      }

      // 旧
      // const option = this.options.filter(
      //   (item) => item.value === this.link_types[0]
      // )[0];
      // const subOption = option.children.filter(
      //   (item) => item.value === this.link_types[1]
      // )[0];
      // let path = [option, subOption];
      // let pathText = `${option.label} | ${subOption.label}`;

      // if (subOption.value === "mini-page") {
      //   pathText = `微页面 | ${this.miniPageItem["page_name"] || ""}`;
      // }
      // if (subOption.value === "shop-home" && this.shopItem) {
      //   pathText = `店铺首页 | ${this.shopItem["shop_name"]}`;
      // }
      // if (subOption.value === "custom") {
      //   pathText = `外链 | ${this.link.value}`;
      // }
      // if (subOption.value === "mini-program") {
      //   pathText = `小程序路径 | ${this.link.value}`;
      // }
      return { path, pathText };
    },
  },
  methods: {
    // 当前弹出窗选择之后
    handleMainSubmit(obj) {
      this.link_types = "";
      this.link = "";

      this.$forceUpdate();
      if (obj.isRefsh) {
        this.$nextTick(() => {
          this.link = obj.params;
          this.$emit("update", this.link);
        });
      }
    },
    /**
     * 选择器值发生改变
     * @param item
     */
    async handleCascaderChange(item) {
      let link = { type: "", label: "", value: "", link_types: item };
      if (item[item.length - 1] === "mini-program"||item[item.length - 1] ==='h5-link') {
       
        if(item[item.length - 1] === "mini-program"){
          link.label = "小程序路径";
          link.type = "mini-program";
        }else{
          link.label = "H5链接";
          link.type = "h5-link";
        }
        link.value = await this.inputMiniProgramLink(link.label);
        
      } else {
        if (item.includes("storePage")) {
          link.type = "storePage";
        } else if (item.includes("goodsType")) {
          link.type = "goodsType";
        } else if (item.includes("projectType")) {
          link.type = "projectType";
        } else if (item.includes("goodsOne")) {
          this.showDialog = true;
          return;
        }else if (item.includes("activity")) {
          this.showActivityDialog = true;
          return;
        }else if (item.includes("decorPage")) {
          this.showDecorDialog = true;
          return;
        }
        link.value = item[item.length - 1];
        link.label = this.forEachTree(this.options, link.value);
      }

      // 旧
      // const link_type = item[item.length - 1];
      // switch (link_type) {
      //   case "custom":
      //     link.value = await this.inputCustomLink();
      //     break;
      //   case "mini-program":
      //     link.value = await this.inputMiniProgramLink();
      //     break;
      //   case "mini-page":
      //     this.show_page_picker = true;
      //     link.value = undefined;
      //     break;
      //   case "shop-home":
      //     link.value = await this.getShopLink();
      //     break;
      //   case "user-center":
      //     link.value = `member`;
      //     break;
      //   case "part-goods":
      //     this.show_goods_picker = true;
      //     link.value = undefined;
      //     break;
      //   default:
      //     link.value = `${link_type}`;
      // }

      if (link.value === undefined) return this.clearLink();
      this.link = link;
      this.$emit("update", this.link);
    },

    // 组装文案
    getText(list) {
      console.log(list,'11111')
      const textAry = [];
      if (list.length) {
        list.forEach((item) => {
          textAry.push(this.forEachTree(this.options, item));
        });
      }

      return textAry.length ? textAry.join(" / ") : "";
    },

    //遍历数组
    forEachTree(tree, value) {
      for (let i = 0; i < tree.length; i++) {
        let item = tree[i];
        if (item.value === value) {
          return item.label;
        } else {
          if (item.children && item.children.length) {
            const res = this.forEachTree(item.children, value);
            if (res) {
              return res;
            }
          }
        }
      }
    },

    // 根据value 查出父节点名称
    /**
     * 清空值
     */
    clearLink() {
      this.$nextTick(() => {
        this.link = "";
        this.link_types = "";
      });
      this.$emit("update", "");
    },
    /**
     * 输入自定义链接
     * @returns {Promise<string>}
     */
    async inputCustomLink() {
      try {
        const res = await this.$prompt("请输入自定义链接", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputPlaceholder: "https://",
          inputPattern: Rules.url,
          inputErrorMessage: "链接格式不正确！",
        });
        return res.value;
      } catch (e) {
        this.clearLink();
      }
    },
    /**
     * 输入小程序路径
     * @returns {Promise<string>}
     */
    async inputMiniProgramLink(label) {
      try {
        const res = await this.$prompt(`请输入${label}`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputPlaceholder: `请输入${label}`,
        });
        return res.value;
      } catch (e) {
        this.clearLink();
      }
    },

    // 解决未知bug 级联选择器再选在中间层级时弹出窗不清除问题
    defaultTypeChange() {
      setTimeout(() => {
        const ele = document.querySelectorAll(".el-popper");
        if (ele.length) {
          for (let i = 0; i < ele.length; i++) {
            ele[i].remove();
          }
        }
      }, 0);
    },
    /**
     * 默认值发生改变
     * @param newVal
     */
    async defaultValueChange(newVal) {
      this.link = newVal;
      if (!newVal) this.link_types = "";
      if (newVal.value) {
        // if (newVal.type === "storePage") {
        this.link_types = newVal.value;
        // }
      }
      // 旧
      // this.link = newVal;
      // if (!newVal) this.link_types = "";
      // let option = "";
      // let subOption = "";
      // for (let i = 0; i < this.options.length; i++) {
      //   option = this.options[i];
      //   if (option.value === newVal.type) break;
      //   if (option.children && option.children.length) {
      //     subOption = option.children.filter(
      //       (item) => item.value === newVal.type
      //     )[0];
      //     if (subOption) break;
      //     option = "";
      //     subOption = "";
      //   }
      // }
      // option && (this.link_types = [option.value]);
      // subOption && (this.link_types = [option.value, subOption.value]);
      // if (newVal && newVal.type === "shop-home" && !this.shopItem) {
      //   this.shopItem = await API_Shop.getShopDetail(newVal.value);
      // }
      // if (newVal && newVal.type === "mini-page" && !this.miniPageItem) {
      //   this.miniPageItem = await API_Pages.getPageDetail(newVal.value, false);
      // }
    },
    /**
     * 获取店铺链接
     * @returns {Promise<string>}
     */
    async getShopLink() {
      if (this.$$ShopInfo) {
        return this.$$ShopInfo["shop_id"];
      } else {
        this.show_shop_picker = true;
        return undefined;
      }
    },
    /**
     * 店铺选择器确认
     * @param items
     */
    handleShopPickerConfirm(items) {
      if (!items.length) return;
      this.shopItem = items[0];
      this.link = {
        type: "shop-home",
        value: items[0]["shop_id"],
      };
      this.$emit("update", this.link);
    },
    /**
     * 商品选择器确认
     * @param items
     */
    handleGoodsPickerConfirm(items) {
      if (!items.length) return;
      this.link = {
        type: "part-goods",
        value: items[0]["goods_id"],
      };
      this.$emit("update", this.link);
    },
    /**
     * 页面选择器确认
     * @param items
     */
    handlePagePickerConfirm(items) {
      if (!items.length) return;
      this.miniPageItem = items[0];
      this.link = {
        type: "mini-page",
        value: items[0].id,
      };
      this.$emit("update", this.link);
    },
  },
};
</script>

<style lang="scss" scoped>
.link-selector {
  position: relative;
  display: flex;
  width: 100%;
  .link-selector__link {
    display: inline-block;
    width: 100%;
  }
  /deep/ {
    .el-tag {
      display: flex;
      align-items: center;
      position: relative;
      color: #155bd4;
      border-color: #155bd4;
      border-radius: 2px;
      width: 100%;
      height: 28px;
      box-sizing: border-box;
      margin-right: 5px;
      overflow: hidden;
      .el-tag__close {
        position: absolute;
        top: auto;
        right: 5px;
        color: #155bd4;
        &:hover {
          color: #ffffff;
          background-color: #155bd4;
        }
      }
      .link-selector__tag-content {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-right: 10px;
        max-width: 208px;
      }
    }
    .el-cascader {
      width: 100%;
    }
  }
}
</style>
