import request from "@/utils/request";
// 获取产品树
export const getProductTree = params => {
  return request({
    url: '/goods/product/classify/query/tree',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 全部分类
 * @param params
 */
export function getProjectCategoryList(params) {
  return request({
    url: `shop/category/query/list`,
    method: 'get',
    loading: false,
    params
  })
}

// 产品列表
export function getProductList(params) {
  return request({
    url: 'goods/product/query/cashFindSearchPage',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 获取项目列表
 * @param params 参数
 */
export function getProjectList(params) {
  return request({
    url: `shop/project/query/findSearchPage`,
    method: 'get',
    params
  })
}

/**
 * 获取项目列表
 * @param params 参数
 */
export function findSkillSearchPage(params) {
  return request({
    url: `distribution/seckillActivity/query/findSearchPage`,
    method: 'get',
    params
  })
}