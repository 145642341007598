<template>
  <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false" :close-on-press-escape="false" title="秒杀" append-to-body width="805px">
    <div class="container">
      <el-table ref="tableDataRef" :data="tableData" :header-cell-style="{ background: '#F8F9FC', color: '#333333' }" height="500" @selection-change="handeleSelect">
        <template slot="empty"><el-empty :description="'暂无数据'"></el-empty></template>
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column :label="'活动名称'" prop="activity_name" width="150"></el-table-column>
        <el-table-column :label="'商品名称'" prop="spu_name" width="150">
        </el-table-column>
        <el-table-column :label="'活动时间'">
          <template slot-scope="scope">
            <span>{{ scope.row.create_time + '~' + scope.row.end_time }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageParams.page_no"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageParams.page_size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <div slot="footer" class="goods-picker-footer">
      <!-- <div class="upload-status-num">
        最多可选个数：<span>{{ limit < 1 ? '无限制' : limit }}</span>
        已选个数：<span>{{ selectedNum }}</span>
        还可选个数：<span>{{ limit < 1 ? '无限制' : limit - selectedNum }}</span>
      </div> -->
      <span>
        <el-button @click="$emit('update:dialogVisible', false)">取 消</el-button>
        <el-button type="primary" @click="handleConfirm">确 定</el-button>
      </span>
    </div>
  </el-dialog>
</template>

<script>
import {findSkillSearchPage} from '@/api/goods'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tableData: [],
      tableSelect:[],
      pageParams: {
        page_no: 1,
        page_size: 10,
      },
      total:0,
    }
  },
  mounted() {
    this.getListFn()
    this.tableSelect = this.$$FormData.goodsList
  },
  methods: {
   async getListFn() {
      const res=await findSkillSearchPage(this.pageParams)
      this.tableData=res.data
      this.total=res.data_total
      this.handleTableSelect()
    },
    //反钩选表格
    async handleTableSelect() {
      await this.$nextTick()
      this.$refs.tableDataRef.clearSelection()
      this.$$FormData.goodsList.forEach(id => {
        const row = this.tableData.find(item => item.seckill_activity_id === id)
        if (row) {
          this.$refs.tableDataRef.toggleRowSelection(row, true)
        }
      })
    },
    handeleSelect(list){
      // this.$$FormData.goodsList=list.map(item=>item.seckill_activity_id)

      // 获取当前页面上所有的product_id
      const currentPageIds = this.tableData.map(item => item.seckill_activity_id);
        
        // 保留不在当前页面的已选项
        const keepSelected = this.tableSelect.filter(item => 
          !currentPageIds.includes(item)
        );
        
        // 添加当前页面新选中的项
        const newSelected = list.map(item=>item.seckill_activity_id)
        
        // 更新productSelect，合并保留的选项和新选中的选项
        this.tableSelect = [...keepSelected, ...newSelected];
        
        // 如果有取消选中的项，也要从projectSelect中移除
        const selectedIds = new Set(this.tableSelect.map(item => item));
        this.projectSelect = this.tableSelect.filter(item => 
          !currentPageIds.includes(item) || selectedIds.has(item)
        );
    },
    // 点击页容量
    handleSizeChange(size) {
      this.pageParams.page_no = 1
      this.pageParams.page_size = size
      this.getListFn()
    },
    // 点击页码查询
    handleCurrentChange(page) {
      this.pageParams.page_no = page
      this.getListFn()
    },
    handleConfirm() {
      this.$$FormData.goodsList=this.tableSelect
      this.$emit('update:dialogVisible', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination {
  text-align: right;
  margin-top: 16px;
}
</style>
