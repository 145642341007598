<template>
  <div class="seckill">
    秒杀模块
  </div>
</template>

<script>
export default {
  name: 'SeckilPreview'
}
</script>

<style lang="scss" scoped>
.seckill {

}
</style>
